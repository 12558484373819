import { SeoMetaTags } from "constants/dataTypes";
import Head from "next/head";

export interface GlobalSeoMetaTagsProps {
    seoMetaTags: SeoMetaTags | null;
  }
  /* eslint-enable no-unused-vars */
  
  const GlobalSeoMetaTags = ({
    seoMetaTags
  }: GlobalSeoMetaTagsProps) => {

    const getPageTitle = (title: string | undefined) => {
        if(!title || title.trim().length === 0) {
            return <></>;
        }
        return (<title>{title}</title>);
    };
    const getMetaTitle = (title: string | undefined) => {
        if(!title || title.trim().length === 0) {
            return <></>;
        }
        return (<meta name="title" content={title} />);
    };

    const getDescription = (description: string | undefined) => {
        if(!description || description.trim().length === 0) {
            return <></>;
        }
        return (<meta name="description" content={description} />);
    };


    //return <Head> with meta title and description
    return (
        <Head>
            {getMetaTitle(seoMetaTags?.meta_title)}
            {getDescription(seoMetaTags?.meta_description)}
            {getPageTitle(seoMetaTags?.page_title)}
        </Head>
    );
  };
  
  export default GlobalSeoMetaTags;
